import { Ability, AbilityBuilder } from '@casl/ability';
import { MANAGER } from '../../constants/jobRoleCategory';
import {
  ROLE_FORWOOD_ID_ADMIN,
  ROLE_LEADERSHIP_PARTICIPATION_ACCESS,
  ROLE_SITE_ADMIN
} from '../../constants/roles';
import { CASL_ACTION } from '../../constants/caslAction';
import { CASL_SUBJECT } from '../../constants/caslSubject';
import * as screens from '../../constants/screens';

function grantForwoodIDAdminPermissions(can) {
  can(CASL_ACTION.view, CASL_SUBJECT.LeadershipParticipationMenu);
  can(CASL_ACTION.view, screens.MAINTAIN_DEFAULT_TARGETS);
  can(CASL_ACTION.view, screens.SET_BULK_TARGETS);
  can(CASL_ACTION.view, screens.SET_INDIVIDUAL_TARGETS);
  can(CASL_ACTION.view, screens.SITE_TARGET_DASHBOARD);
  can(CASL_ACTION.view, screens.TEAM_TARGET_DASHBOARD);
}

function grantSiteAdminPermissions(can) {
  can(CASL_ACTION.view, CASL_SUBJECT.LeadershipParticipationMenu);
  can(CASL_ACTION.view, screens.SET_BULK_TARGETS);
  can(CASL_ACTION.view, screens.SET_INDIVIDUAL_TARGETS);
  can(CASL_ACTION.view, screens.SITE_TARGET_DASHBOARD);
  can(CASL_ACTION.view, screens.TEAM_TARGET_DASHBOARD);
}

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  // this one is required
  // eslint-disable-next-line no-underscore-dangle
  return item.__type;
}

const ability = new Ability([], { subjectName });

export function updateAbility(currentAbility, decodedIdToken) {
  const { can, rules } = new AbilityBuilder();

  if (decodedIdToken && decodedIdToken['cognito:groups']
  && decodedIdToken['cognito:groups'].includes(ROLE_LEADERSHIP_PARTICIPATION_ACCESS)
  ) {
    can(CASL_ACTION.view, CASL_SUBJECT.HomePage);
  }

  if (
    decodedIdToken
    && decodedIdToken['cognito:groups']
    && decodedIdToken['cognito:groups'].includes(ROLE_LEADERSHIP_PARTICIPATION_ACCESS)
    && decodedIdToken['cognito:groups'].includes(ROLE_FORWOOD_ID_ADMIN)
  ) {
    grantForwoodIDAdminPermissions(can);
  }

  if (
    decodedIdToken
    && decodedIdToken['cognito:groups']
    && decodedIdToken['cognito:groups'].includes(ROLE_LEADERSHIP_PARTICIPATION_ACCESS)
    && (decodedIdToken.job_role_category === MANAGER
    || decodedIdToken['cognito:groups'].includes(ROLE_SITE_ADMIN))
  ) {
    grantSiteAdminPermissions(can);
  }

  currentAbility.update(rules);
}

export default ability;
